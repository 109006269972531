import React, { useState, useEffect, FormEvent } from 'react'
import { useNavigate } from 'react-router-dom'
import '../Login.css' // Import CSS file
const Login: React.FC = () => {
  const [password, setPassword] = useState<string>('')
  const navigate = useNavigate()

  // Hardcoded password
  const correctPassword = 'B0undl3ss!LCG'

  // Check if user is already authenticated
  useEffect(() => {
    const isAuthenticated = localStorage.getItem('authenticated')
    if (isAuthenticated) {
      navigate('/swap_device')
    }
  }, [navigate]) // useEffect triggers when the component mounts

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (password === correctPassword) {
      localStorage.setItem('authenticated', 'true')
      navigate('/swap_device')
    } else {
      alert('Incorrect password')
    }
  }

  return (
    <div>
      <h2>Login</h2>
      <form onSubmit={handleSubmit}>
        <input
          type="password"
          placeholder="Enter Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button type="submit">Login</button>
      </form>
    </div>
  )
}

export default Login

import './App.css';
import CsvReader from './Components/CsvReader';
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import Login from './Components/Login'
import ProtectedRoute from './Components/ProtectedRoute';
function App() {
  return (

    <div className='App'>
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route
            path="/swap_device"
            element={
              <ProtectedRoute>
                <CsvReader></CsvReader>
              </ProtectedRoute>
            }
          />
          <Route path="*" element={<Navigate to="/" />} />

        </Routes>
      </Router>
    </div>

  );
}

export default App;

import React, { useState } from 'react';
import Papa from 'papaparse';

export interface SwapConfig {
    existing_serial: string;
    replacement_serial: string;
    wan_1_up: number | null;
    wan_1_down: number | null;
    status?: number | null; // Status code (e.g., 200 for success)
    message: string; // Status message (e.g., "Success" or error message)
}

const CsvReader: React.FC = () => {
    const [file, setFile] = useState<File | null>(null); // To store the selected file
    const [swapConfigs, setSwapConfigs] = useState<SwapConfig[]>([]); // Store parsed data
    const [isProcessing, setIsProcessing] = useState(false); // To indicate whether processing is ongoing
    const [currentIndex, setCurrentIndex] = useState<number | null>(null); // Track the current index being processed

    // Handle file input change
    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFile = e.target.files?.[0];
        if (selectedFile && selectedFile.type === 'text/csv') { // Check if file is a CSV
            setFile(selectedFile); // Set file only if it's a CSV
        } else {
            setFile(null); // Reset file if it's not CSV
            alert('Please select a valid CSV file.');
        }
    };

    // Handle submit button click
    const handleSubmit = async () => {
        if (file) {
            setIsProcessing(true); // Mark that the processing has started
            try {
                const data = await parseCsv(file); // Parse the CSV file
                const configs = mapToSwapConfig(data); // Map CSV rows to SwapConfig instances
                setSwapConfigs(configs); // Store the parsed swap configs
                await processSwapsStepByStep(configs); // Process each config step-by-step
            } catch (error) {
                console.error("Error parsing CSV: ", error);
                alert('Error occurred during swap!');
            } finally {
                setIsProcessing(false); // Mark that processing is finished
            }
        } else {
            alert("Please select a CSV file first.");
        }
    };

    // Process each swapConfig one by one
    const processSwapsStepByStep = async (configs: SwapConfig[]) => {
        for (let i = 0; i < configs.length; i++) {
            setCurrentIndex(i); // Set the index of the config currently being processed
            try {
                // Simulate swap operation
                const response = await swapDevices(configs[i]); // Simulate device swap and return status and message
                console.log("response:", response)
                configs[i].status = response.status; // Update status code (e.g., 200 for success)
                configs[i].message = response.message; // Update message with success or failure reason
            } catch (error) {
                configs[i].status = 500; // Mark as failed (500 status code for internal error)
                configs[i].message = 'Swap failed'; // Error message for failure
            }
            setSwapConfigs([...configs]); // Update the swapConfigs state after each step
        }

        setCurrentIndex(null)
    };

    // Simulate the swap device operation
    const swapDevices = async (config: SwapConfig) => {
        const response = await fetch('https://vihv5huyr2.execute-api.us-east-1.amazonaws.com/swap_devices', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(config),
        });
        const data = await response.json()

        return { "status": response.status, "message": data.message }
    };

    // Helper function to simulate delay
    const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

    // Function to parse the CSV file using async/await
    const parseCsv = (file: File): Promise<any[]> => {
        return new Promise((resolve, reject) => {
            Papa.parse(file, {
                complete: (result) => {
                    resolve(result.data); // Resolve the promise with parsed data
                },
                error: (error: Error) => {
                    reject(error); // Reject the promise if there's an error
                },
                skipEmptyLines: true, // Skip empty lines in the CSV
                header: true, // Parse the first row as headers
            });
        });
    };

    const mapToSwapConfig = (data: any[]): SwapConfig[] => {
        return data.map((row: any) => {
            return {
                existing_serial: row.existing_serial || '',
                replacement_serial: row.replacement_serial || '',
                wan_1_up: row.wan_1_up ? Number(row.wan_1_up) : null,
                wan_1_down: row.wan_1_down ? Number(row.wan_1_down) : null,
                status: null, // Initialize status as null
                message: '', // Initialize message as an empty string
            };
        });
    };

    return (
        <div>
            <h1>Swap Devices</h1>

            {/* File input */}
            <input type="file" accept=".csv" onChange={handleFileChange} />

            {/* Submit button */}
            <button
                onClick={handleSubmit}
                disabled={!file || isProcessing} // Disable while processing
                style={{
                    backgroundColor: file && !isProcessing ? '#173773' : '#cccccc',
                    color: file && !isProcessing ? 'white' : '#666666',
                    cursor: file && !isProcessing ? 'pointer' : 'not-allowed',
                    padding: '10px 20px',
                    border: 'none',
                    borderRadius: '5px',
                    fontSize: '16px',
                    marginTop: '10px',
                }}
            >
                {isProcessing ? 'Swapping Devices...' : 'Submit'}
            </button>

            {/* Display the SwapConfig data in a table after processing */}
            {swapConfigs.length > 0 && (
                <div>
                    <h2>Swap Config Data:</h2>
                    <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                        <thead>
                            <tr>
                                <th style={{ padding: '20px', textAlign: 'left' }}>Existing Serial</th>
                                <th style={{ padding: '20px', textAlign: 'left' }}>Replacement Serial</th>
                                <th style={{ padding: '20px', textAlign: 'left' }}>WAN 1 Up</th>
                                <th style={{ padding: '20px', textAlign: 'left' }}>WAN 1 Down</th>
                                <th style={{ padding: '20px', textAlign: 'left' }}>Status Code</th> {/* Add column for status code */}
                                <th style={{ padding: '20px', textAlign: 'left' }}>Message</th> {/* Add column for status message */}
                            </tr>
                        </thead>
                        <tbody>
                            {swapConfigs.map((config, index) => (
                                <tr
                                    key={index}
                                    style={{
                                        backgroundColor: index === currentIndex ? '#cccccc' : 'transparent', // Highlight current row
                                    }}
                                >
                                    <td style={{ padding: '20px' }}>{config.existing_serial}</td>
                                    <td style={{ padding: '20px' }}>{config.replacement_serial}</td>
                                    <td style={{ padding: '20px' }}>{config.wan_1_up !== null ? config.wan_1_up : 'N/A'}</td>
                                    <td style={{ padding: '20px' }}>{config.wan_1_down !== null ? config.wan_1_down : 'N/A'}</td>
                                    <td style={{ padding: '20px', color: config.status === 200 ? 'green' : 'red' }}>
                                        {config.status !== null ? config.status : 'Pending'}
                                    </td>
                                    <td style={{ padding: '20px' }}>
                                        {config.message}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );
};

export default CsvReader;

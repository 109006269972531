import React from "react";
import { Navigate } from "react-router-dom";

interface ProtectedRouteProps {
    children: JSX.Element;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
    const isAuthenticated = localStorage.getItem("authenticated");

    // If not authenticated, redirect to login page
    if (!isAuthenticated) {
        return <Navigate to="/" />;
    }

    // If authenticated, allow access to the protected page
    return children;
};

export default ProtectedRoute;
